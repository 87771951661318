<template>
  <div class="inner-section">
    <bread-cumb />
    <body-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('teaGarden.return_two') + ' ' + ($route.query.id ? $t('globalTrans.update') : $t('globalTrans.entry')) }}</h4>
      </template>
      <template v-slot:body>
          <b-overlay :show="!dataLoaded">
            <b-row>
                <b-col sm="12" v-if="!dataLoaded" style="min-height: 400px"></b-col>
                <b-col sm="12" v-else>
                    <form-wizard class="fair-tab-wrapper" shape="circle" color="#005B5B" title="" subtitle="" :start-index.sync="activeIndex">
                        <tab-content :title="$t('teaGarden.product_information')" icon="ri-list-settings-line" :before-change="tab1Submit" >
                            <Tab1
                                :app_id="app_id"
                                :tab_main="tab_main"
                                ref="tab_1_form"/>
                        </tab-content>
                        <tab-content :title="$t('teaGarden.sale_information_made_tea')" icon="ri-list-settings-line" :before-change="tabLastSubmit">
                            <TabLast
                            :app_id="app_id"
                            :tab_five="tab_five"
                            :total_production="tab_main.gros_total_production"
                            :total_sale_info="total_sale_info"
                            :app_status="app_status"
                            :main_tab="tab_main"
                            ref="tab_last_form"/>
                        </tab-content>
                        <template slot="footer" slot-scope="props">
                            <div class="wizard-footer-right">
                                <wizard-button class="btn btn-secondary rounded-pill" @click.native="props.prevTab(), step--"  v-if="props.activeTabIndex > 0" :style="props.fillButtonStyle">{{ $t('globalTrans.back') }}</wizard-button>
                                <wizard-button
                                    type="submit"
                                    class="btn btn-warning ml-1 mr-1 rounded-pill"
                                    title="Create Draft and Save!"
                                    @click.native="props.nextTab(), draft = 1"
                                >
                                    {{$t('globalTrans.save_draft')}}
                                </wizard-button>
                                <wizard-button
                                    type="submit"
                                    v-if="!props.isLastStep"
                                    @click.native="props.nextTab(), isNext = true"
                                    class="btn btn-success ml-1 mr-1 rounded-pill"
                                    title="Draft and go to next!"
                                    :style="props.fillButtonStyle"
                                >{{ $t('globalTrans.draft_n_next') }}
                                </wizard-button>
                                <wizard-button
                                    v-else
                                    type="submit"
                                    class="btn btn-success ml-1 rounded-pill"
                                    title="Submit!"
                                    @click.native="finalSave(), app_status = 2"
                                >
                                    {{ $t('globalTrans.finalSave')}}
                                </wizard-button>
                            </div>
                        </template>
                    </form-wizard>
                </b-col>
            </b-row>
          </b-overlay>
      </template>
    </body-card>
  </div>
</template>
<script>

import Tab1 from './Tab1'
// import Tab2 from './Tab2'
import TabLast from './TabLast'

import BreadCumb from '@/components/BreadCumb.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WizardButton from 'vue-form-wizard/src/components/WizardButton.vue'
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnTwoDetailsApi } from '../../api/routes'

export default {
  components: {
    BreadCumb, FormWizard, TabContent, WizardButton, Tab1, TabLast
  },
  name: 'Form',
  props: [],
  data () {
    return {
        baseUrl: teaGardenServiceBaseUrl,
        valid: null,
        errors: [],
        step: 0,
        activeIndex: 0,
        tab_main: {
          year: 0,
          month: 0,
          return_type: 0,
          garden_has_own_tea_factory: 0,
          factory_has_own_tea_garden: 0,
          garden_fact_id: 0,
          manufacture_info: 0,

            green_leaf_quantity: 0,
            c_t_c: 0,
            green_tea: 0,
            orthodox_tea: 0,
            yellow_tea: 0,
            white_tea: 0,
            others_tea: 0,
            total_production: 0,
            production_recovery: 0,
            recovery_ratio: 0,

            bought_green_leaf_quantity: 0,
            bought_c_t_c: 0,
            bought_green_tea: 0,
            bought_orthodox_tea: 0,
            bought_yellow_tea: 0,
            bought_white_tea: 0,
            bought_others_tea: 0,
            bought_production_sub_total: 0,
            bought_total_green_leaf_quantity: 0,
            bought_total_production: 0,
            bought_production_recovery: 0,

            factory_c_t_c: 0,
            factory_green_tea: 0,
            factory_orthodox_tea: 0,
            factory_yellow_tea: 0,
            factory_white_tea: 0,
            factory_others_tea: 0,
            factory_total_production: 0,
            gros_total_production: 0,

          details: []
        },
        tab_five: [
            {
                disposal_type_id: 1,
                quantity: 0,
                total_value: 0,
                avg_value: 0
            },
            {
                disposal_type_id: 2,
                quantity: 0,
                total_value: 0,
                avg_value: 0
            },
            {
                disposal_type_id: 3,
                quantity: 0,
                total_value: 0,
                avg_value: 0
            },
            {
                disposal_type_id: 4,
                quantity: 0,
                total_value: 0,
                avg_value: 0
            },
            {
                disposal_type_id: 5,
                quantity: 0,
                total_value: 0,
                avg_value: 0
            },
            {
                disposal_type_id: 6,
                quantity: 0,
                total_value: 0,
                avg_value: 0
            }
        ],
        total_sale_info: {
            quantity: 0,
            total_value: 0,
            avg_value: 0
        },
        app_id: 0,
        app_status: 1,
        draft: 0,
        dataLoaded: false
    }
  },
  created () {
    window.scrollTo(0, 0)
    if (this.$route.query.id) {
        this.app_id = this.$route.query.id
        this.getDetailsData()
    } else {
        this.dataLoaded = true
    }
  },
  computed: {
    loading: function () {
        return this.$store.state.commonObj.loading
    }
  },
  methods: {
    finalSave () {
        this.$swal({
            title: this.$t('globalTrans.final_save_msg'),
            showCancelButton: true,
            confirmButtonText: this.$t('globalTrans.yes'),
            cancelButtonText: this.$t('globalTrans.no'),
            focusConfirm: false
        }).then((result) => {
            if (result.isConfirmed) {
                this.tabLastSubmit()
            } else {
                this.app_status = 1
            }
        })
    },
    async getDetailsData () {
        const result = await RestApi.getData(teaGardenServiceBaseUrl, returnTwoDetailsApi, { app_id: this.app_id })
        if (result.success) {
            const data = result.data
            if (data) {
                // tab - 1
                this.tab_main = {
                    year: data.year,
                    month: data.month,
                    return_type: data.return_type,
                    garden_fact_id: data.garden_fact_id,
                    garden_has_own_tea_factory: data.has_own_tea_factory,
                    manufacture_info: data.sale_type,

                    green_leaf_quantity: data.g_leaf_qty,
                    c_t_c: data.ctc,
                    green_tea: data.green_tea,
                    orthodox_tea: data.orthodox_tea,
                    yellow_tea: data.yellow_tea,
                    white_tea: data.white_tea,
                    others_tea: data.others_tea,
                    total_production: data.garden_total_production,
                    production_recovery: data.garden_recovery_rate,

                    bought_green_leaf_quantity: data.bought_g_leaf_qty,
                    bought_c_t_c: data.bought_ctc,
                    bought_green_tea: data.bought_green_tea,
                    bought_orthodox_tea: data.bought_orthodox_tea,
                    bought_yellow_tea: data.bought_yellow_tea,
                    bought_white_tea: data.bought_white_tea,
                    bought_others_tea: data.bought_others_tea,
                    bought_total_green_leaf_quantity: data.total_g_leaf_qty,
                    bought_total_production: data.bought_total_production,
                    bought_production_recovery: data.bought_recovery_rate,
                    recovery_ratio: data.garden_recovery_rate,

                    factory_c_t_c: data.ctc,
                    factory_green_tea: data.green_tea,
                    factory_orthodox_tea: data.orthodox_tea,
                    factory_yellow_tea: data.yellow_tea,
                    factory_white_tea: data.white_tea,
                    factory_others_tea: data.others_tea,
                    factory_total_production: data.garden_total_production,
                    factory_production_recovery: data.garden_recovery_rate,
                    gros_total_production: data.gros_total_production
                }
                this.activeIndex = 1
                // tab - 5
                if (Object.keys(data.sales).length > 0) {
                    this.tab_five = data.sales
                }
            }
            this.dataLoaded = true
        } else {
            this.dataLoaded = false
        }
    },
    async tabLastSubmit () {
        const result = await this.$refs.tab_last_form.submit()
        if (result.success) {
            this.$router.push({ path: 'return-form-2' })
        }
    },
    async tab1Submit () {
        // return true
        const result = await this.$refs.tab_1_form.submit()
        if (result.success) {
            if (this.draft) {
                this.$router.push({ path: 'return-form-2' })
            } else {
                this.app_id = result.data.id
                return true
            }
        }
    }
  }
}
</script>
