<template>
    <b-row>
        <b-col md="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <!-- total production and open in stock -->
                                                <h6 class="text-center">{{$t('teaGarden.total_production')}} : {{ $n(total_production) }}</h6>
                                                <h6 class="text-center">{{$t('teaGarden.open_stock_beg')}} : {{ $n(prev_opening_stock) }}</h6>
                                                <h6 class="text-center">{{$t('teaGarden.available_sale_qty')}} : {{ $n(availableSaleQty) }}</h6>
                                                 <hr> <br>
                                                <b-form-group v-for="(item, index) in tab_five" :key="index" label-cols-lg="3" :label="labelList(item.disposal_type_id)" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.quantity_kg', 'en')" vid="quantity_kg" :rules="'required|max_value:' + availableSaleQty" v-slot="{ errors }">
                                                                <b-form-group label-for="quantity_kg">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.quantity_kg') }}
                                                                    </template>
                                                                    <b-form-input v-model="item.quantity" @input="avgCal(item)" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.average_value', 'en')" vid="total_value" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="total_value">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.average_value') }}
                                                                    </template>
                                                                    <b-form-input v-model="item.total_value" @input="avgCal(item)" :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.total_value', 'en')" vid="average_value" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="average_value">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.total_value') }}
                                                                    </template>
                                                                    <b-form-input v-model="item.avg_value" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.total_sale')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.quantity_kg', 'en')" vid="quantity_kg" :rules="'required|max_value:' + availableSaleQty" v-slot="{ errors }">
                                                                <b-form-group label-for="quantity_kg">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.quantity_kg') }}
                                                                    </template>
                                                                    <b-form-input v-model="total_sale_info.quantity" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.average_value', 'en')" vid="total_value" rules="required|min:1|max:10" v-slot="{ errors }">
                                                                <b-form-group label-for="total_value">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.average_value') }}
                                                                    </template>
                                                                    <b-form-input v-model="total_sale_info.total_value" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider :name="$t('teaGarden.total_value', 'en')" vid="average_value" rules="required|min:1|max:15" v-slot="{ errors }">
                                                                <b-form-group label-for="average_value">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.total_value') }}
                                                                    </template>
                                                                    <b-form-input v-model="total_sale_info.avg_value" disabled :state="errors[0] ? false : (valid ? true : null)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                    </b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnTwoTabLastStoreApi, returnTwoOpeningStockApi } from '../../api/routes'

export default {
    props: ['app_id', 'tab_five', 'total_sale_info', 'total_production', 'app_status', 'main_tab'],
    components: { },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false,
            prev_opening_stock: 0
        }
    },
    created () {
        if (this.app_id > 0) {
            this.totalSaleCal()
        }
        this.getOpeningStock()
    },
    computed: {
        currentLocale () {
            return this.$i18n.locale
        },
        availableSaleQty () {
            return this.total_production + this.prev_opening_stock
        }
    },
    methods: {
        // avgCal (item) {
        //     const item1 = parseFloat(item.quantity) ? parseFloat(item.quantity) : 0
        //     const item2 = parseFloat(item.total_value) ? parseFloat(item.total_value) : 0
        //     item.avg_value = (item1 * item2).toFixed(2)
        //     this.totalSaleCal()
        // },
        avgCal (item) {
            const item1 = parseFloat(item.quantity) ? parseFloat(item.quantity) : 0
            const item2 = parseFloat(item.total_value) ? parseFloat(item.total_value) : 0
            item.avg_value = (item1 * item2).toFixed(2)
            this.totalSaleCal()
        },
        totalSaleCal () {
           let totalSaleQty = 0
        //    let totalSalePrice = 0
           let totalSaleAvg = 0
            for (const obj of this.tab_five) {
                totalSaleQty += parseFloat(obj.quantity)
                // totalSalePrice += parseFloat(obj.total_value)
                totalSaleAvg += parseFloat(obj.avg_value)
            }
            this.total_sale_info.quantity = totalSaleQty
            // this.total_sale_info.total_value = totalSalePrice
            this.total_sale_info.avg_value = totalSaleAvg.toFixed(2)
            this.total_sale_info.total_value = (totalSaleAvg / totalSaleQty).toFixed(2)
        },
        labelList (id) {
            if (id === 1) {
                return this.$t('teaGarden.loose_tea')
            }
            if (id === 2) {
                return this.$t('teaGarden.packet_tea')
            }
            if (id === 3) {
                return this.$t('teaGarden.dispatch_for_auction')
            }
            if (id === 4) {
                return this.$t('teaGarden.direct_contract_export')
            }
            if (id === 5) {
                return this.$t('teaGarden.consignment_to_london_auction')
            }
            if (id === 6) {
                return this.$t('teaGarden.free_distribution')
            }
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check && this.total_sale_info.quantity <= this.availableSaleQty) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                this.tab_five.map(item => {
                    item.ret_main_id = this.app_id
                })
                const salesData = {
                    app_id: this.app_id,
                    app_satus: this.app_status,
                    total_sale_qty: this.total_sale_info.quantity,
                    total_sale_value: this.total_sale_info.total_value,
                    total_sale_avg_value: this.total_sale_info.avg_value,
                    active_role_id: this.$store.state.Auth.activeRoleId,
                    details: this.tab_five
                }
                // this.tab_five.app_id = this.app_id
                // this.tab_five.app_status = this.app_status
                const result = await RestApi.postData(teaGardenServiceBaseUrl, returnTwoTabLastStoreApi, salesData)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: this.$t('globalTrans.form_error_msg'),
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$t('globalTrans.form_error_msg'),
                    color: '#ee5253'
                })
            }
        },
        async getOpeningStock () {
            const data = {
                return_type: this.main_tab.return_type,
                garden_fact_id: this.main_tab.garden_fact_id,
                month: this.main_tab.month,
                year: this.main_tab.year
            }
            const result = await RestApi.postData(teaGardenServiceBaseUrl, returnTwoOpeningStockApi, data)
            if (result.success) {
                this.prev_opening_stock = result.prev_opening_stock
            }
        }
    }
}
</script>
