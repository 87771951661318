<template>
    <b-row>
        <b-col sm="12">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
                    <b-overlay :show="unitLoad">
                        <div class="application-form-wrapper">
                            <div class="application-itmes">
                                <b-row>
                                    <b-col sm="12">
                                        <div class="group-form-card">
                                            <b-card>
                                                <b-form-group label-cols-lg="3" :label="$t('teaGarden.area_under_tea')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0">
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Year" vid="year" rules="required|min_value:1" v-slot="{ errors }">
                                                            <b-form-group label-for="year">
                                                                <template v-slot:label>
                                                                    {{ $t('globalTrans.year') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select plain v-model="tab_main.year"
                                                                    :options="yearList"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                       <b-col sm="6">
                                                            <ValidationProvider name="Month" vid="month" rules="required|min_value:1">
                                                                <b-form-group
                                                                    slot-scope="{ valid, errors }"
                                                                    label-for="month">
                                                                        <template v-slot:label>
                                                                        {{ $t('globalTrans.month') }} <span class="text-danger">*</span>
                                                                        </template>
                                                                        <b-form-select
                                                                            plain
                                                                            v-model="tab_main.month"
                                                                            :options="monthList"
                                                                            id="month"
                                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                                            >
                                                                            <template v-slot:first>
                                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                            </template>
                                                                        </b-form-select>
                                                                    <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Return Type" vid="return_type" rules="required|min_value:1" v-slot="{ errors }">
                                                                <b-form-group label-for="return_type">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.return_type') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-radio-group v-model="tab_main.return_type"
                                                                        :options="returnTypeList"
                                                                        :disabled="isGardenAdminCheckGardenId() ||isFactoryAdminCheckFactoryId() ? true : false"
                                                                        @change="resetGarden()"
                                                                        value-field="value" text-field="text"
                                                                        :state="errors[0] ? false : (valid ? true : null)">
                                                                    </b-form-radio-group>
                                                                    <div class="invalid-feedback d-block">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="tab_main.return_type && tab_main.return_type === 1">
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Garden Factory Id" vid="garden_fact_id" rules="required|min_value:1" v-slot="{ errors }">
                                                            <b-form-group label-for="garden_fact_id">
                                                                <template v-slot:label>
                                                                {{ $t('teaGarden.tea_garden') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="tab_main.garden_fact_id"
                                                                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId() ? true : false"
                                                                    :options="masterTeaGardenGenInfoList"
                                                                    @change="getGfAutoId(tab_main.garden_fact_id, 'garden')"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="tab_main.garden_fact_id" sm="6">
                                                            <ValidationProvider name="Garden ID" vid="garden_id" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="garden_id">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.tea_garden_id') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="gf_auto_id" disabled :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                          <b-col sm="6" v-if="tab_main.return_type == 1">
                                                            <ValidationProvider name="Garden has own tea factory" vid="garden_has_own_tea_factory" rules="required|min_value:1">
                                                            <b-form-group
                                                                slot-scope="{ valid, errors }"
                                                                label-for="garden_has_own_tea_factory">
                                                                <template v-slot:label>
                                                                {{ $t('teaGarden.garden_has_own_tea_factory') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-radio-group
                                                                    v-model="tab_main.garden_has_own_tea_factory"
                                                                    :options="yesNoList"
                                                                     :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId()? true : false"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-radio-group>
                                                                <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                           <b-col sm="6" v-if="tab_main.garden_has_own_tea_factory === 2 && tab_main.return_type === 1">
                                                            <ValidationProvider name="Garden has own tea factory" vid="manufacture_info" rules="required|min_value:1">
                                                            <b-form-group
                                                                slot-scope="{ valid, errors }"
                                                                label-for="manufacture_info">
                                                                <template v-slot:label>
                                                                <!-- {{ $t('teaGarden.manufacture_info') }} <span class="text-danger">*</span> -->
                                                                </template>
                                                                <b-form-radio-group
                                                                    v-model="tab_main.manufacture_info"
                                                                     :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId()? true : false"
                                                                    @input="getPreMonthData()"
                                                                    :options="manufactureList"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-radio-group>
                                                                <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="tab_main.return_type && tab_main.return_type === 2">
                                                        <b-col sm="6">
                                                            <ValidationProvider name="Garden Factory" vid="garden_fact_id" rules="required|min_value:1" v-slot="{ errors }">
                                                            <b-form-group label-for="garden_fact_id">
                                                                <template v-slot:label>
                                                                    {{ $t('teaGarden.bought_leaf_factory') }} <span class="text-danger">*</span>
                                                                </template>
                                                                <b-form-select
                                                                    plain
                                                                    v-model="tab_main.garden_fact_id"
                                                                    :options="masterBoughtLeafFactoryList"
                                                                    :disabled="isGardenAdminCheckGardenId() || isFactoryAdminCheckFactoryId()? true : false"
                                                                    @change="getGfAutoId(tab_main.garden_fact_id, 'factory')"
                                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                                >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                                </b-form-select>
                                                                <div class="invalid-feedback">
                                                                    {{ errors[0] }}
                                                                </div>
                                                            </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                        <b-col v-if="tab_main.garden_fact_id" sm="6">
                                                            <ValidationProvider name="Factory ID" vid="factory_id" rules="required" v-slot="{ errors }">
                                                                <b-form-group label-for="factory_id">
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.bought_auto_id') }} <span class="text-danger">*</span>
                                                                    </template>
                                                                    <b-form-input v-model="gf_auto_id" disabled :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                </b-form-group>
                                                            </ValidationProvider>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row v-if="!isReady">
                                                        <b-col sm="12" class="py-3">
                                                            <h6 v-if="$i18n.locale == 'en'">Searching Previous Month Data!<br>Please wait...</h6>
                                                            <h6 v-else>আগের বছরের ডেটা অনুসন্ধান করা হচ্ছে!<br>অনুগ্রহপূর্বক অপেক্ষা করুন...</h6>
                                                        </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group v-if="isReady && tab_main.return_type == 1" label-cols-lg="3" :label="$t('teaGarden.manufactured_during_the_month')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <!-- for garden -->
                                                             <b-col sm="6">
                                                                <ValidationProvider name="Green Leaf Quantity" vid="green_leaf_quantity" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="green_leaf_quantity"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.green_leaf_quantity') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="green_leaf_quantity"
                                                                        v-model="tab_main.green_leaf_quantity"
                                                                        v-on:keyup="calGardenRecoveryRate(tab_main.return_type,tab_main.garden_has_own_tea_factory)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider v-if="tab_main.garden_has_own_tea_factory == 1 || tab_main.manufacture_info == 1" name="C.T.C" vid="c_t_c" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="c_t_c"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.c_t_c') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="c_t_c"
                                                                        v-model="tab_main.c_t_c"
                                                                        v-on:keyup="totalProOwnGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col v-if="tab_main.garden_has_own_tea_factory == 1 || tab_main.manufacture_info == 1"  sm="6">
                                                                <ValidationProvider name="Green Tea" vid="green_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="green_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.green_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="green_tea"
                                                                        v-model="tab_main.green_tea"
                                                                        v-on:keyup="totalProOwnGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col v-if="tab_main.garden_has_own_tea_factory == 1 || tab_main.manufacture_info == 1" sm="6">
                                                                <ValidationProvider name="Orthodox Tea" vid="orthodox_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="orthodox_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.orthodox_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="orthodox_tea"
                                                                        v-model="tab_main.orthodox_tea"
                                                                        v-on:keyup="totalProOwnGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col v-if="tab_main.garden_has_own_tea_factory == 1 || tab_main.manufacture_info == 1" sm="6">
                                                                <ValidationProvider name="Yellow Tea" vid="yellow_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="yellow_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.yellow_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="yellow_tea"
                                                                        v-model="tab_main.yellow_tea"
                                                                        v-on:keyup="totalProOwnGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col v-if="tab_main.garden_has_own_tea_factory == 1 || tab_main.manufacture_info == 1" sm="6">
                                                                <ValidationProvider name="White Tea" vid="white_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="white_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.white_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="white_tea"
                                                                        v-model="tab_main.white_tea"
                                                                        v-on:keyup="totalProOwnGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col v-if="tab_main.garden_has_own_tea_factory == 1 || tab_main.manufacture_info == 1" sm="6">
                                                                <ValidationProvider name="Orthers Tea" vid="others_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="others_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.others_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="others_tea"
                                                                        v-model="tab_main.others_tea"
                                                                        v-on:keyup="totalProOwnGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col v-if="tab_main.manufacture_info == 2 && tab_main.garden_has_own_tea_factory != 1" sm="6">
                                                                <ValidationProvider name="Recovery Ratio" vid="recovery_ratio" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="recovery_ratio"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.recovery_ratio') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="recovery_ratio"
                                                                        v-model="tab_main.recovery_ratio"
                                                                        v-on:keyup="calSaleOtherTotalPro(tab_main.return_type,tab_main.garden_has_own_tea_factory,tab_main.manufacture_info)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Total Production" vid="total_production" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="total_production"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.total_production') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="total_production"
                                                                        v-model="tab_main.total_production"
                                                                        disabled
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col v-if="tab_main.garden_has_own_tea_factory == 1 || tab_main.manufacture_info == 1" sm="6">
                                                                <ValidationProvider name="Production Recovery Rate" vid="production_recovery" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="production_recovery"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.production_recovery') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="production_recovery"
                                                                        v-model="tab_main.production_recovery"
                                                                        disabled
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group v-if="isReady && tab_main.return_type === 1 && tab_main.garden_has_own_tea_factory === 1" label-cols-lg="3" :label="$t('teaGarden.bought_leaf_factory_kg')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <!-- for garden -->
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Green Leaf Quantity" vid="green_leaf_quantity" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="green_leaf_quantity"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.green_leaf_quantity') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="green_leaf_quantity"
                                                                        v-model="tab_main.bought_green_leaf_quantity"
                                                                        v-on:keyup="calTotalGreenLeaf(tab_main.return_type,tab_main.garden_has_own_tea_factory)"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="C.T.C" vid="c_t_c" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="c_t_c"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.c_t_c') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="c_t_c"
                                                                        v-model="tab_main.bought_c_t_c"
                                                                        v-on:keyup="totalProBoughtLeafGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Green Tea" vid="green_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="green_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.green_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="green_tea"
                                                                        v-model="tab_main.bought_green_tea"
                                                                        v-on:keyup="totalProBoughtLeafGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Orthodox Tea" vid="orthodox_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="orthodox_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.orthodox_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="orthodox_tea"
                                                                        v-model="tab_main.bought_orthodox_tea"
                                                                        v-on:keyup="totalProBoughtLeafGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Yellow Tea" vid="yellow_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="yellow_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.yellow_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="yellow_tea"
                                                                        v-model="tab_main.bought_yellow_tea"
                                                                        v-on:keyup="totalProBoughtLeafGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="White Tea" vid="white_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="white_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.white_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="white_tea"
                                                                        v-model="tab_main.bought_white_tea"
                                                                        v-on:keyup="totalProBoughtLeafGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Orthers Tea" vid="others_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="others_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.others_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="others_tea"
                                                                        v-model="tab_main.bought_others_tea"
                                                                        v-on:keyup="totalProBoughtLeafGarden()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <!-- <b-col  sm="6">
                                                                <ValidationProvider name="Production sub total" vid="production_sub_total" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="production_sub_total"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.production_sub_total') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="production_sub_total"
                                                                        v-model="tab_main.bought_production_sub_total"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col> -->
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Total Green Leaf Quantity" vid="total_green_leaf_quantity" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="total_green_leaf_quantity"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.total_green_leaf_quantity') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="total_green_leaf_quantity"
                                                                        v-model="tab_main.bought_total_green_leaf_quantity"
                                                                        disabled
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col  sm="6">
                                                                <ValidationProvider name="Total Production" vid="total_production" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="total_production"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.total_production') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="total_production"
                                                                        v-model="tab_main.bought_total_production"
                                                                        disabled
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Production Recovery Rate" vid="production_recovery" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="production_recovery"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.production_recovery') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="production_recovery"
                                                                        v-model="tab_main.bought_production_recovery"
                                                                        disabled
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                    </b-row>
                                                </b-form-group>
                                                <b-form-group v-if="isReady && tab_main.return_type === 2" label-cols-lg="3" :label="$t('teaGarden.manufactured_during_the_month')" label-size="lg"
                                                    label-class="font-weight-bold pt-0" class="mb-0 mt-4">
                                                    <b-row>
                                                        <!-- for garden -->
                                                            <b-col sm="6">
                                                                <ValidationProvider name="C.T.C" vid="c_t_c" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="c_t_c"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.c_t_c') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="c_t_c"
                                                                        v-model="tab_main.factory_c_t_c"
                                                                        v-on:keyup="factoryTotalProduction()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Green Tea" vid="green_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="green_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.green_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="green_tea"
                                                                        v-model="tab_main.factory_green_tea"
                                                                        v-on:keyup="factoryTotalProduction()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Orthodox Tea" vid="orthodox_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="orthodox_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.orthodox_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="orthodox_tea"
                                                                        v-model="tab_main.factory_orthodox_tea"
                                                                        v-on:keyup="factoryTotalProduction()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Yellow Tea" vid="yellow_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="yellow_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.yellow_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="yellow_tea"
                                                                        v-model="tab_main.factory_yellow_tea"
                                                                        v-on:keyup="factoryTotalProduction()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="White Tea" vid="white_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="white_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.white_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="white_tea"
                                                                        v-model="tab_main.factory_white_tea"
                                                                         v-on:keyup="factoryTotalProduction()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col sm="6">
                                                                <ValidationProvider name="Orthers Tea" vid="others_tea" rules="" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="others_tea"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.others_tea') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="others_tea"
                                                                        v-model="tab_main.factory_others_tea"
                                                                         v-on:keyup="factoryTotalProduction()"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                            <b-col  sm="6">
                                                                <ValidationProvider name="Total Production" vid="total_production" rules="required" v-slot="{ errors }">
                                                                    <b-form-group
                                                                    label-for="total_production"
                                                                    >
                                                                    <template v-slot:label>
                                                                        {{ $t('teaGarden.total_production') }}
                                                                    </template>
                                                                    <b-form-input
                                                                        id="total_production"
                                                                        v-model="tab_main.factory_total_production"
                                                                        disabled
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                                    ></b-form-input>
                                                                    <div class="invalid-feedback">
                                                                        {{ errors[0] }}
                                                                    </div>
                                                                    </b-form-group>
                                                                </ValidationProvider>
                                                            </b-col>
                                                    </b-row>
                                                </b-form-group>
                                            </b-card>
                                        </div>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </b-overlay>
                </b-form>
            </ValidationObserver>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { returnTwoTabOneStoreApi } from '../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
import { helpers } from '@/utils/helper-functions'

export default {
    props: ['app_id', 'tab_main'],
    mixins: [teaGardenService],
    components: { },
    data () {
        return {
            errors: [],
            valid: null,
            unitLoad: false,
            gf_auto_id: '',
            isReady: true
        }
    },
    created () {
        if (this.$route.query.id) {
            const typeName = this.tab_main.return_type === 1 ? 'garden' : 'factory'
            this.getGfAutoId(this.tab_main.garden_fact_id, typeName)
        } else {
            this.tab_main.year = new Date().getFullYear()
            if (this.isGardenAdminCheckGardenId()) {
                const gardenData = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(item => item.value === this.isGardenAdminCheckGardenId())
                this.tab_main.return_type = 1
                this.tab_main.manufacture_info = gardenData.manufacture_or_sale_info != null ? gardenData.manufacture_or_sale_info : 0
                this.tab_main.garden_has_own_tea_factory = gardenData.has_own_garden != null ? gardenData.has_own_garden : 0
                if (gardenData.has_own_garden === 2) {
                    this.tab_main.manufacture_info = gardenData.manufacture_or_sale_info != null ? gardenData.manufacture_or_sale_info : 0
                }
                this.tab_main.garden_fact_id = this.isGardenAdminCheckGardenId()
                const typeName = this.tab_main.return_type === 1 ? 'garden' : 'factory'
                this.getGfAutoId(this.tab_main.garden_fact_id, typeName)
            }
            if (this.isFactoryAdminCheckFactoryId()) {
                const gardenData = this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.find(item => item.value === this.isFactoryAdminCheckFactoryId())
                this.tab_main.return_type = gardenData.factory_type_id
                this.tab_main.garden_fact_id = this.isFactoryAdminCheckFactoryId()
                const typeName = this.tab_main.return_type === 1 ? 'garden' : 'factory'
                this.getGfAutoId(this.tab_main.garden_fact_id, typeName)
            }
        }
    },
    watch: {
        'tab_main.total_production': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.calGrosTotalProduction()
            }
        },
        'tab_main.bought_total_production': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.calGrosTotalProduction()
            }
        },
        'tab_main.factory_total_production': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.calGrosTotalProduction()
            }
        }
    },
    computed: {
        yearList () {
            return helpers.getYearList()
        },
        monthList () {
            return this.$store.state.commonObj.monthList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
            })
        },
        yesNoList () {
            return [
                { text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes', value: 1 },
                { text: this.$i18n.locale === 'bn' ? 'না' : 'No', value: 2 }
            ]
        },
        manufactureList () {
            return [
                { text: this.$i18n.locale === 'bn' ? 'অন্য বাগানে উৎপাদন' : 'Manufacture In Other Garden', value: 1 },
                { text: this.$i18n.locale === 'bn' ? 'অন্য বাগানে বিক্রয়' : 'Sale to Other Garden', value: 2 }
            ]
        },
        masterTeaGardenGenInfoList () {
            return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(el => el.status === 1)
        },
        masterBoughtLeafFactoryList () {
            return this.$store.state.TeaGardenService.commonObj.masterBoughtLeafFactoryList.filter(el => el.status === 1 && el.factory_type_id === 2)
        },
        returnTypeList () {
            return this.$store.state.TeaGardenService.commonObj.returnTypeList.map(item => {
                return { value: item.value, text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn }
            })
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        resetGarden () {
            this.tab_main.garden_fact_id = 0
            this.gf_auto_id = ''
        },
        customLoading (type) {
            if (type) {
                this.isReady = false
            } else {
                this.isReady = true
            }
        },
        calGrosTotalProduction () {
            const returnType = this.tab_main.return_type
            if (returnType === 1 && this.tab_main.garden_has_own_tea_factory === 1) {
                this.tab_main.gros_total_production = parseFloat(this.tab_main.total_production) + parseFloat(this.tab_main.bought_total_production)
            }
            if (returnType === 1 && this.tab_main.garden_has_own_tea_factory === 2) {
                this.tab_main.gros_total_production = parseFloat(this.tab_main.total_production)
            }
            if (returnType === 2) {
                this.tab_main.gros_total_production = parseFloat(this.tab_main.factory_total_production)
            }
        },
        calGardenRecoveryRate (returnType, hasOwnGarden) {
            if (returnType === 1 && hasOwnGarden === 1) {
                const totalPro = parseFloat(this.tab_main.total_production)
                const greenLeafQty = parseFloat(this.tab_main.green_leaf_quantity)
                const divDed = (totalPro / greenLeafQty)
                this.tab_main.production_recovery = (divDed * 100).toFixed(2)
            }
            if (returnType === 1 && hasOwnGarden === 2 && this.tab_main.manufacture_info === 1) {
                const totalPro = parseFloat(this.tab_main.total_production)
                const greenLeafQty = parseFloat(this.tab_main.green_leaf_quantity)
                const divDed = (totalPro / greenLeafQty)
                this.tab_main.production_recovery = (divDed * 100).toFixed(2)
            }
            if (returnType === 1 && hasOwnGarden === 2 && this.tab_main.manufacture_info === 2) {
                this.calSaleOtherTotalPro(returnType, hasOwnGarden, this.tab_main.manufacture_info)
            }
        },
        calBoughtLeafRecoveryRate (returnType, hasOwnGarden) {
            if (returnType === 1 && hasOwnGarden === 1) {
                const BoughtTotalPro = parseFloat(this.tab_main.bought_total_production)
                const BoughtLeafQty = parseFloat(this.tab_main.bought_green_leaf_quantity)
                const divDed = (BoughtTotalPro / BoughtLeafQty)
                this.tab_main.bought_production_recovery = (divDed * 100).toFixed(2)
            }
        },
        calSaleOtherTotalPro (returnType, hasOwnGarden, manufactureInfo) {
            if (returnType === 1 && hasOwnGarden === 2 && manufactureInfo === 2) {
                this.tab_main.total_production = 0
                const greenLeafQty = parseFloat(this.tab_main.green_leaf_quantity)
                const ratio = parseFloat(this.tab_main.recovery_ratio)
                this.tab_main.total_production = (greenLeafQty * ratio).toFixed(2)
            }
        },
        calTotalGreenLeaf (returnType, hasOwnGarden) {
            if (returnType === 1 && hasOwnGarden === 1) {
                const greenLeafQty = parseFloat(this.tab_main.green_leaf_quantity)
                const boughtGreenQty = parseFloat(this.tab_main.bought_green_leaf_quantity)
                this.tab_main.bought_total_green_leaf_quantity = (greenLeafQty + boughtGreenQty).toFixed(2)
                this.calBoughtLeafRecoveryRate(this.tab_main.return_type, this.tab_main.garden_has_own_tea_factory)
            }
        },
        totalProOwnGarden () {
            const etc = parseFloat(this.tab_main.c_t_c)
            const greenTea = parseFloat(this.tab_main.green_tea)
            const orthodoxTea = parseFloat(this.tab_main.orthodox_tea)
            const yellowTea = parseFloat(this.tab_main.yellow_tea)
            const whiteTea = parseFloat(this.tab_main.white_tea)
            const othersTea = parseFloat(this.tab_main.others_tea)

            this.tab_main.total_production = (etc + greenTea + orthodoxTea + yellowTea + whiteTea + othersTea).toFixed(2)
            this.calGardenRecoveryRate(this.tab_main.return_type, this.tab_main.garden_has_own_tea_factory)
        },
        totalProBoughtLeafGarden () {
            const etc = parseFloat(this.tab_main.bought_c_t_c)
            const greenTea = parseFloat(this.tab_main.bought_green_tea)
            const orthodoxTea = parseFloat(this.tab_main.bought_orthodox_tea)
            const yellowTea = parseFloat(this.tab_main.bought_yellow_tea)
            const whiteTea = parseFloat(this.tab_main.bought_white_tea)
            const othersTea = parseFloat(this.tab_main.bought_others_tea)

            this.tab_main.bought_total_production = (etc + greenTea + orthodoxTea + yellowTea + whiteTea + othersTea).toFixed(2)
            this.calBoughtLeafRecoveryRate(this.tab_main.return_type, this.tab_main.garden_has_own_tea_factory)
        },
        factoryTotalProduction () {
            const etc = parseFloat(this.tab_main.factory_c_t_c)
            const greenTea = parseFloat(this.tab_main.factory_green_tea)
            const orthodoxTea = parseFloat(this.tab_main.factory_orthodox_tea)
            const yellowTea = parseFloat(this.tab_main.factory_yellow_tea)
            const whiteTea = parseFloat(this.tab_main.factory_white_tea)
            const othersTea = parseFloat(this.tab_main.factory_others_tea)

            this.tab_main.factory_total_production = (etc + greenTea + orthodoxTea + yellowTea + whiteTea + othersTea).toFixed(2)
            this.calBoughtLeafRecoveryRate(this.tab_main.return_type, this.tab_main.garden_has_own_tea_factory)
        },
        getGfAutoId (Id, typeName) {
            this.gf_auto_id = ''
            const List = typeName === 'garden' ? this.masterTeaGardenGenInfoList : this.masterBoughtLeafFactoryList
            const Obj = List.find(el => el.value === parseInt(Id))
            if (typeof Obj !== 'undefined') {
                this.gf_auto_id = typeName === 'garden' ? Obj.garden_id_no : Obj.registration_no
            }
        },
        async submit () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.unitLoad = true
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const loadingState = { loading: false, listReload: false }
                this.tab_main.app_id = this.app_id
                const result = await RestApi.postData(teaGardenServiceBaseUrl, returnTwoTabOneStoreApi, this.tab_main)
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.unitLoad = false
                if (result.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: this.app_id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    return result
                } else {
                    this.$toast.error({
                        title: this.$t('globalTrans.error'),
                        message: result.message,
                        color: '#ee5253'
                    })
                    this.$refs.form.setErrors(result.errors)
                }
            }
        }
    }
}
</script>
